import React, { useState, useEffect } from "react"
import QuackImage from "../../images/quack-image-transparent.png"
import trackStatusGif from "../../images/TrackStatus.gif"
import goalGraph from "../../images/GoalGraph.gif"
import changeStyling from "../../images/ChangeStyling.gif"
import quackHire from "../../images/QuackHire.gif"


const LandingPage = () => {
    const [imageLoading, setImageLoading] = useState(true);
    const [currentGif, setCurrentGif] = useState(0);
    const [gifArray] = useState([
        { source: trackStatusGif, alt: 'Track Status feature gif example', text: 'Track jobs with a single click', border: 'none', maxWidth: '800px', margin: '0 10% 0 0'},
        { source: goalGraph, alt: 'Goal Graph feature gif example', text: 'Set and track daily goals', border: 'none', maxWidth: '800px', margin: '0 10% 0 0'},
        { source: changeStyling, alt: 'Change Styling feature gif example', text: 'Modify styling by status', border: '1px solid #1351a8', width: 'calc(~"(100vh - 150px")*.767)', margin: '-2% 5% 0 0'},
        { source: quackHire, alt: 'Quackhire dashboard gif example', text: 'Search for and manage jobs on Quackhire.com', border: '1px solid #1351a8', margin: '-2% 5% 0 0'},
    ]);

    useEffect(() => {
        if(!imageLoading){
            if(currentGif === 0){ setTimeout(function(){ setCurrentGif(1) }, 11500); }
            if(currentGif === 1){ setTimeout(function(){ setCurrentGif(2) }, 13516); }
            if(currentGif === 2){ setTimeout(function(){ setCurrentGif(3) }, 13576); }
            if(currentGif === 3){ setTimeout(function(){ setCurrentGif(0) }, 13509); }
        }
    }, [currentGif, imageLoading])

    return (
        <>
            <div className = "landing-container">
                <div className = "text-container">
                    <div className = "text-content">
                        <h1>Click. Save. Apply.</h1>
                        <h2>Get Hired faster.</h2>
                    </div>
                    <p>Keeping track of jobs you find online is hard. Bookmarking, tracking them on a spreadsheet, or just keeping them open in separate tabs gets messy fast. With the Quackhire Chrome extension you can save jobs from your favorite job sites with a single click, or block them and never see them again. We track which jobs you have viewed so you don’t spend hours looking at the same listings over and over again.<br/><br/>No more wasted time, no more missed opportunities.</p>
                    <a href="https://chrome.google.com/webstore/detail/quackhire/jmpkknkklhccclhobkgdbedobhenljlk" target="_blank" rel="noopener noreferrer"><button>Install Now</button></a>
                </div>
                <div className ="img-container" style={{margin: gifArray[currentGif].margin, maxWidth: gifArray[currentGif].maxWidth}}>
                    <h2>{gifArray[currentGif].text}</h2>
                    <img src={gifArray[currentGif].source} alt={gifArray[currentGif].alt} style={{border: gifArray[currentGif].border}} onLoad={()=>{setImageLoading(false)}}/>
                </div>
            </div>
        </>
    )
}

export default LandingPage